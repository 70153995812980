/**
* Generated automatically at built-time (2024-10-07T09:26:46.683Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "ulkokeittio-moduulit",templateKey: "sites/106-d02b010c-7917-4624-9d2b-223d920f31e7"};