/**
* Generated automatically at built-time (2024-10-07T09:26:46.833Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "terasopas-ulkokeittio",templateKey: "sites/106-51bdea17-7f4a-46b4-ab1c-13d4727ce904"};